import React from 'react';
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {renderProvider} from "../util";

export default ({classes, hcc}) => {
    return <Card className={classes.card}>
        <CardContent>
            <Typography className={classes.title}>Chronic Conditions</Typography>
            {hcc.length ?
                <List>

                    {hcc.map((hcc, i) =>
                        <ListItem key={i}>
                            <ListItemText
                                primary={`${hcc.diagnosisCode} — ${hcc.diagnosisDescription}`}
                                secondary={
                                    <span>{hcc.startDate} — {renderProvider(hcc.providerName, hcc.providerSpecialty)}</span>}
                            />
                        </ListItem>
                    )}
                </List> :
                <span>No data.</span>
            }
        </CardContent>
    </Card>;
}
import React from "react";
import Card from "@material-ui/core/Card/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar/Avatar";
import PersonIcon from "@material-ui/icons/Person";
import { formatIdentifiers } from "../util";

export default ({ age, dateOfBirth, sex, displayId, planId, name }) => {
  return (
    <div>
      <Card style={{ boxShadow: "none" }} className="MemberCard screen-only">
        <CardHeader
          title={
            <div>
              {name} <span style={{ color: "rgba(0, 0, 0, 0.54)" }}>({sex})</span> &mdash;{" "}
              {formatIdentifiers({
                displayId,
                planId,
              })}
            </div>
          }
          subheader={
            <div>
              {dateOfBirth} ({age} years)
            </div>
          }
          avatar={
            <Avatar>
              <PersonIcon />
            </Avatar>
          }
          className="MemberCardHeader"
        />
      </Card>
      <div className="print-only MemberCard-print">
        {name} ({displayId})
      </div>
    </div>
  );
};

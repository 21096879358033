import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Card from '@material-ui/core/Card';
import {fetchMemberDetails} from "../api";
import {CircularProgress} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography/Typography";
import MemberMenu from "../MemberMenu";
import MemberCard from "../member/MemberCard";

class Overview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            dateOfBirth: '',
            sex: '',
            id: '',
            displayId: '',
            age: '',
            pcpName: '',
            isLoading: true,
        };
        fetchMemberDetails(props.match.params.id).then(memberDetails => {
            this.setState({...memberDetails, isLoading: false});
        }).catch(e => {
            console.error(e);
            this.setState({
                isLoading: false,
                errorMessage: e.errorMessage
            })
        });
    }

    render() {
        const history = this.props.history;
        const {id, displayId, isLoading, phone, address, pcpName, program, errorMessage} = this.state;
        const classes = this.props.classes;
        if (isLoading) {
            return <CircularProgress/>;
        } else if (errorMessage) {
            return <p style={{margin: 10}}>{errorMessage}</p>;
        }
        return isLoading ? <CircularProgress/> : (
            <div>
                <div style={{margin: 10, paddingBottom: 56}}>
                    <MemberCard {...this.state} />
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography className={classes.title}>Demographics</Typography>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Address</TableCell>
                                        <TableCell>{address}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Phone</TableCell>
                                        <TableCell>{phone}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>

                    <Card className={classes.card}>
                        <CardContent>
                            <Typography className={classes.title}>Health Plan</Typography>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Program</TableCell>
                                        <TableCell>{program}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Member ID</TableCell>
                                        <TableCell>{displayId}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>PCP</TableCell>
                                        <TableCell>{pcpName}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                </div>
                <MemberMenu selectedIndex={0} history={history} memberId={id}/>
            </div>
        );
    }
}

const styles = theme => ({
    card: {
        marginBottom: 20
    },
    title: {
        marginBottom: 16,
        fontSize: 14,
        color: theme.palette.text.secondary,
    }
});

export default withStyles(styles)(Overview);

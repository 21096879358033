import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LocalPharmacy from '@material-ui/icons/LocalPharmacy';
import './ClaimListItem.css';
import {renderProvider} from "../util";

export default ({ drugDispensed, daysSupply, dateOfService, providerName, providerSpecialty, serviceRendered, isMedication }) => (
    isMedication ?
        <ListItem className="ClaimListItem ClaimListItem-drug" title="Medications">
            <ListItemText
                primary={`${drugDispensed} — ${daysSupply} days`}
                secondary={`${dateOfService} — ${renderProvider(providerName, providerSpecialty)}`}
            />
            <ListItemIcon className="ClaimListItem-icon"><LocalPharmacy /></ListItemIcon>
        </ListItem> :
        <ListItem className="ClaimListItem ClaimListItem-claim">
            <ListItemText
                primary={serviceRendered}
                secondary={`${dateOfService} — ${renderProvider(providerName, providerSpecialty)}`}
            />
        </ListItem>
);
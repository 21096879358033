import auth from './auth';
import { formatDate } from "./util";

function getUrl() {
    if (process.env.NODE_ENV !== 'production') {
        return 'http://localhost:8000/api';
    } else {
        const matches = /env=([a-z]+)/.exec(window.location.search);
        return `https://${matches ? matches[1] : 'go'}.cisnehealth.com/api`;
    }
}

const API_URL = getUrl();

export const get = path => {
    const headers = new Headers();
    headers.append('Authorization', 'Bearer ' + auth.getAccessToken());
    const query = window.location.search;
    return fetch(`${API_URL}${path}${query}`, {
        headers: headers
    });
};

export const fetchMembers = () => {
    return new Promise((resolve, reject) => {
        get('/members').then(response => {
            handleJsonResponse(response, resolve, reject);
        }).catch(e => reject(e));
    }).then(members => members.filter(member => member.name !== null));
};

export const fetchMemberDetails = (memberId) => {
    return new Promise((resolve, reject) => {
        get('/members/' + memberId).then(response => {
            handleJsonResponse(response, resolve, reject);
        }).catch(e => reject(e));
    });
};

export const fetchMlr = (memberId) => {
    return new Promise((resolve, reject) => {
        get('/members/' + memberId + '/mlr').then(response => {
            handleJsonResponse(response, resolve, reject);
        }).catch(e => reject(e));
    });
};

export const fetchHcc = (memberId) => {
    return new Promise((resolve, reject) => {
        get('/members/' + memberId + '/hcc').then(response => {
            handleJsonResponse(response, resolve, reject);
        }).catch(e => reject(e));
    });
};

export const fetchMedications = (memberId) => {
    return new Promise((resolve, reject) => {
        get('/members/' + memberId + '/medications').then(response => {
            handleJsonResponse(response, resolve, reject);
        }).catch(e => reject(e));
    }).then(data => data.map(item => ({
      ...item,
      dateOfService: formatDate(item.dateOfService)
    })));
};

function handleJsonResponse(response, resolve, reject) {
    if (response.status !== 200) {
        response.json().then(response => {
            reject(response);
        });
    } else {
        response.json().then(json => {
            resolve(json);
        });
    }
}

import numeral from "numeral";

export function formatDecimal(value) {
    return value === null ? '—' : numeral(value).format('0,0.000')
}

export function formatCurrency(value) {
    return value === null ? '—' :numeral(value).format('$0,0');
}

export function formatPercentage(value) {
    return value === null ? '—' :numeral(value).format('0.0%');
}

export function formatDate(value) {
    if (typeof value === "string") {
      if (value.includes('/')) {
        return value; // date is already formatted
      }
      value = new Date(value);
    }
    return value.toLocaleDateString(
      "en-US",
      {
          month: "2-digit",
          day: "2-digit",
          year: "numeric"
      }
    );
}

export function formatIdentifiers({displayId, planId}) {
    const identifiers = new Set();
    if (displayId) {
        identifiers.add(displayId);
    }
    if (planId) {
        identifiers.add(planId);
    }
    return Array.from(identifiers).join(", ")
}

export function renderProvider(name, specialty) {
    return name + (specialty ? ' (' + specialty + ')' : '');
}

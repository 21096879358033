import React from 'react';
import BottomNavigation  from '@material-ui/core/BottomNavigation';
import Icon from '@material-ui/core/Icon';
import BottomNavigationButton from "@material-ui/core/BottomNavigationAction";
import withStyles from "@material-ui/core/styles/withStyles";

const nav = ({history, selectedIndex, classes, memberId}) => {
  return (
  <BottomNavigation value={selectedIndex} className={classes.root + ' screen-only'} position="fixed">
     <BottomNavigationButton
       label="Summary"
       icon={<Icon className="material-icons">restore</Icon>}
       onClick={() => history.push(`/${memberId}/overview${window.location.search}`)}
       showLabel={true}
     />
     <BottomNavigationButton
       label="MLR"
       icon={<Icon className="material-icons">show_chart</Icon>}
       onClick={() => history.push(`/${memberId}/mlr${window.location.search}`)}
       showLabel={true}
     />
     <BottomNavigationButton
       label="HCC"
       icon={<Icon className="material-icons">list</Icon>}
       onClick={() => history.push(`/${memberId}/hcc${window.location.search}`)}
       showLabel={true}
     />
     <BottomNavigationButton
       label="Medications"
       icon={<Icon className="material-icons">more</Icon>}
       onClick={() => history.push(`/${memberId}/medications${window.location.search}`)}
       showLabel={true}
     />
   </BottomNavigation>
 )
};

const styles = {
    root: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        left: 0
    },
};

export default withStyles(styles)(nav);
import React from 'react';
import {fetchMemberDetails, fetchMlr} from "../api";
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/core/styles';
import MemberMenu from "../MemberMenu";
import MemberCard from "../member/MemberCard";
import MlrCard from "../member/MlrCard";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

class Overview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
        };
        fetchMemberDetails(props.match.params.id).then(memberDetails => {
            this.setState({memberDetails: memberDetails});
            return fetchMlr(props.match.params.id);
        }).then(mlr => {
            this.setState({mlr: mlr, isLoading: false});
        }).catch(e => {
            console.error(e);
            this.setState({
                isLoading: false,
                errorMessage: e.errorMessage
            })
        });
    }

    render() {
        const history = this.props.history;
        const classes = this.props.classes;
        const {isLoading, errorMessage, mlr} = this.state;
        if (isLoading) {
            return <CircularProgress/>;
        } else if (errorMessage) {
            return <p style={{margin: 10}}>{errorMessage}</p>;
        }
        return isLoading ? <CircularProgress/> : (
            <div>
                <div style={{margin: 10, paddingBottom: 56}}>
                    <MemberCard {...this.state.memberDetails} />
                    {mlr.length ?
                        mlr.map((mlr, i) =>
                            <MlrCard key={i} classes={classes} {...mlr} />
                        )
                        :
                        <Card>
                            <CardContent style={{paddingBottom: 16}}>No data.</CardContent>
                        </Card>
                    }
                </div>
                <MemberMenu selectedIndex={1} history={history} memberId={this.props.match.params.id}/>
            </div>
        );
    }
}

const styles = theme => ({
    card: {
        marginBottom: 20
    },
    title: {
        marginBottom: "16px !important",
        fontSize: "14px !important",
        color: theme.palette.text.secondary,
    }
});

export default withStyles(styles)(Overview);
import auth0 from 'auth0-js';

const isDevEnv = window.location.hostname === "127.0.0.1";

const BASE_URL = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;

export default class Auth {
  auth0 = new auth0.WebAuth({
    domain: isDevEnv ? 'cisnehealth-test.auth0.com' : 'cisnehealth.auth0.com',
    clientID: isDevEnv ? 'LOtkoXFQ06d3vxBzfqJNygpYAHXmzLz0' : 'W3j5jr1Vs9oif2iAyAwTwgxvMVTrLDvq',
    redirectUri: `${BASE_URL}/auth0/callback`,
    audience: 'https://beta.cisnehealth.com/api',
    responseType: 'token id_token',
    scope: 'openid profile preview app_metadata'
  });

 login(silentlyAuthenticatedCb) {
    localStorage.setItem('redirect_url', window.location.pathname + window.location.search);
    this.auth0.checkSession({}, (err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
            this.setSession(authResult);
            silentlyAuthenticatedCb();
        } else if (err) {
            this.auth0.authorize();
        }
    })
 }

 handleAuthentication(history) {
   const url = localStorage.getItem('redirect_url') || '/';
   localStorage.removeItem('redirect_url');
   this.auth0.parseHash((err, authResult) => {
     if (authResult && authResult.accessToken && authResult.idToken) {
       this.setSession(authResult);
       history.replace(url);
     } else if (err) {
       history.replace(url);
       console.log(err);
     }
   });
 }

 setSession(authResult) {
   let expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
   localStorage.setItem('access_token', authResult.accessToken);
   localStorage.setItem('id_token', authResult.idToken);
   localStorage.setItem('expires_at', expiresAt);
 }

 logout() {
   localStorage.removeItem('access_token');
   localStorage.removeItem('id_token');
   localStorage.removeItem('expires_at');
   this.auth0.logout({
       returnTo: BASE_URL
   });
 }

 isAuthenticated() {
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
 }

 getAccessToken() {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      throw new Error('No access token found');
    }
    return accessToken;
  }

  getIdToken() {
     const accessToken = localStorage.getItem('id_token');
     if (!accessToken) {
       throw new Error('No ID token found');
     }
     return accessToken;
   }

  getProfile(cb) {
    let accessToken = this.getAccessToken();
    this.auth0.client.userInfo(accessToken, (err, profile) => {
      if (profile) {
        console.log(profile);
        this.userProfile = profile;
      }
      cb(err, profile);
    });
  }
}

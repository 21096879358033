import React from 'react';
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import List from "@material-ui/core/List";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ClaimListItem from './ClaimListItem';

export default class MedicationsCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showOnlyMedications: true
        }
    }

    render() {
        const {classes, medications} = this.props;
        return <Card className={classes.card + ' Card'}>
            <CardContent className="CardContent">
                <Typography className={classes.title + ' screen-only'}>Medications</Typography>
                <FormControlLabel
                    className="screen-only"
                    control={
                        <Switch
                            color="primary"
                            checked={this.state.showOnlyMedications}
                            onChange={() => { this.setState({ showOnlyMedications: !this.state.showOnlyMedications }) }}
                            aria-label="Show only medications"
                        />
                    }
                    label="Show only medications"
                />
                <List>
                    {(this.state.showOnlyMedications ? medications.filter(m => m.isMedication) : medications).map((medications, i) =>
                        <ClaimListItem key={i} {...medications} />
                    )}
                </List>
            </CardContent>
        </Card>;
    }
}

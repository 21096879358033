import React from 'react';
import {fetchMedications, fetchMemberDetails} from "../api";
import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import MemberMenu from "../MemberMenu";
import MemberCard from "../member/MemberCard";
import MedicationsCard from "../member/MedicationsCard";

class Overview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
        };
        fetchMemberDetails(props.match.params.id).then(memberDetails => {
            this.setState({ memberDetails: memberDetails });
            return fetchMedications(props.match.params.id);
        }).then(medications => {
            this.setState({ medications: medications, isLoading: false });
        }).catch(e => {
            console.error(e);
            this.setState({
                isLoading: false,
                errorMessage: e.errorMessage
            })
        });
    }

    render() {
        const history = this.props.history;
        const classes = this.props.classes;
        const {isLoading, errorMessage, medications} = this.state;
        if (isLoading) {
            return <CircularProgress />;
        } else if (errorMessage) {
            return <p style={{margin: 10}}>{errorMessage}</p>;
        }
        return isLoading ? <CircularProgress/> : (
            <div>
                <div className="MainContent">
                    <MemberCard {...this.state.memberDetails} />
                    <MedicationsCard medications={medications} classes={classes}/>
                </div>
                <MemberMenu selectedIndex={3} history={history} memberId={this.props.match.params.id}/>
            </div>
        );
    }
}

const styles = theme => ({
    card: {
        marginBottom: 20
    },
    title: {
        marginBottom: 16,
        fontSize: 14,
        color: theme.palette.text.secondary,
    }
});

export default withStyles(styles)(Overview);
import React from 'react';
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Card from "@material-ui/core/Card/Card";
import {formatCurrency, formatPercentage, formatDecimal} from "../util";

export default ({classes, year, claims, premium, surplus, mraP, mra, mlr}) => {
    return <Card className={classes.card}>
        <CardContent>
            <Typography className={classes.title}>MLR {year}</Typography>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>MRA</TableCell>
                        <TableCell style={{textAlign: 'right'}}>{formatDecimal(mra)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>MRA_P</TableCell>
                        <TableCell style={{textAlign: 'right'}}>{formatDecimal(mraP)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Premium</TableCell>
                        <TableCell style={{textAlign: 'right'}}>{formatCurrency(premium)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Claims</TableCell>
                        <TableCell style={{textAlign: 'right'}}>{formatCurrency(claims)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Surplus</TableCell>
                        <TableCell style={{textAlign: 'right'}}>{formatCurrency(surplus)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>MLR</TableCell>
                        <TableCell style={{textAlign: 'right'}}>{formatPercentage(mlr)}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </CardContent>
    </Card>;
}
import React, {Component} from 'react';
import {
    Route,
    Redirect
} from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Mlr from './pages/MedicalLossRatio';
import Overview from './pages/Overview';
import {MuiThemeProvider, createTheme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import MenuItem from '@material-ui/core/MenuItem';
import Drawer from '@material-ui/core/Drawer';
import Auth0Callback from './auth/Auth0Callback';
import auth from './auth';
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import IconButton from "@material-ui/core/IconButton/IconButton";
import PrintIcon from '@material-ui/icons/Print';
import Typography from "@material-ui/core/Typography/Typography";
import MenuIcon from '@material-ui/icons/Menu';
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List/List";
import Hcc from "./pages/Hcc";
import Medications from "./pages/Medications";

const theme = createTheme();

const styles = theme => ({
    root: {
        marginTop: theme.spacing(3),
        width: '100%',
    },
    title: {
        flex: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    drawerList: {
        width: 200
    }
});

class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false
        }
    }

    render() {
        if (/access_token|id_token|error/.test(this.props.location.hash)) {
            auth.handleAuthentication(this.props.history);
            return (<div>Authenticating &hellip;</div>);
        } else if (!auth.isAuthenticated()) {
            auth.login(() => this.setState(this.state));
            return (<p>Redirecting to login page&hellip;</p>);
        } else {
            const classes = this.props.classes;
            return (
                <MuiThemeProvider theme={theme}>
                    <AppBar title="Cisne Healthbook" position="static" className="screen-only">
                        <Toolbar>
                            <IconButton color="inherit" aria-label="Menu" className={classes.menuButton} onClick={() => this.toggleMenu()}>
                                <MenuIcon/>
                            </IconButton>
                            <Typography type="title" color="inherit" className={classes.title}>
                                Cisne Healthbook
                            </Typography>
                            <IconButton color="inherit" aria-label="Print" title="Print" className={classes.menuButton} onClick={() => this.print()}>
                                <PrintIcon/>
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <Drawer open={this.state.menuOpen} onClose={() => this.toggleMenu()} anchor="left">
                        <List className={classes.drawerList}>
                            <MenuItem onClick={() => this.navigate('/')}>Home</MenuItem>
                            <MenuItem onClick={() => auth.logout()}>Logout</MenuItem>
                        </List>
                    </Drawer>
                    <div>
                        <Route exact path="/" render={() => <Redirect to={'/members/' + window.location.search} /> }/>
                        <Route exact path="/members/:filter?" component={Home}/>
                        <Route exact path="/auth0/callback" component={Auth0Callback}/>
                        <Route exact path="/:id/mlr" component={Mlr}/>
                        <Route exact path="/:id/hcc" component={Hcc}/>
                        <Route exact path="/:id/overview" component={Overview}/>
                        <Route exact path="/:id/medications" component={Medications}/>
                    </div>
                </MuiThemeProvider>
            );
        }
    }

    navigate(path) {
        this.props.history.push(path + window.location.search);
        this.setState({menuOpen: false});
    }

    toggleMenu() {
        this.setState({menuOpen: !this.state.menuOpen});
    }

    print() {
        window.print();
    }
}

export default withStyles(styles)(Layout);

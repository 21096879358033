import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import './App.css';
import Layout from './Layout';
import 'typeface-roboto'

export default class App extends Component {
  render() {
      return (
        <Router>
          <Route  path="/" component={Layout} />
        </Router>
      );
  }
}

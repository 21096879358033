import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import {fetchMembers} from '../api';
import TextField from '@material-ui/core/TextField';
import {CircularProgress} from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import {formatIdentifiers} from "../util";

export default class Members extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            members: [],
            filter: props.match.params.filter || '',
            isLoading: true
        };
        fetchMembers().then(members => {
            this.setState({
                members: members,
                isLoading: false
            });
        }).catch(e => {
            this.setState({
                isLoading: false,
                errorMessage: e.errorMessage,
                members: []
            })
        });
    }

    render() {
        return (
            <div style={{margin: 10}}>
                <SearchIcon />
                <TextField
                    autoFocus
                    type="search"
                    helperText="Search for members"
                    style={{width: '90%'}}
                    onChange={ event => this.onFilter(event.target.value) }
                    value={this.state.filter}
                /><br />
                {this.state.isLoading ? <CircularProgress /> : this.state.errorMessage ? <p>{this.state.errorMessage}</p>:
                    <List>
                        {this.state.members.filter(member => this.isMatch(member)).slice(0, 10).map(member =>
                            <ListItem
                                button
                                key={member.id}
                                onClick={() => this.props.history.push(`/${member.id}/overview${window.location.search}`)}
                            >
                                <ListItemAvatar>
                                    <Avatar><PersonIcon /></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={member.name + ' — ' + formatIdentifiers(member)} secondary={<span>{member.dateOfBirth}<br/>{member.address}</span>}/>
                            </ListItem>
                        )}
                    </List>
                }
            </div>
        );
    }

    onFilter(filterValue) {
        this.props.history.replace(`/members/${encodeURIComponent(filterValue)}${window.location.search}`);
        this.setState({ filter: filterValue });
    }

    isMatch(member) {
        const words = this.state.filter.toLowerCase().split(/[ ,]/);
        const value = (member.name || '').toLowerCase() + (member.id ? String(member.id).toLowerCase() : '') + member.dateOfBirth;
        for (let i = 0; i < words.length; ++i) {
            if (!value.includes(words[i])) {
                return false;
            }
        }
        return true;
    }
}
